import { TicketshopPage } from "components/TicketshopPage";
import React from "react";

export default function () {
  return (
    <TicketshopPage
      title="The secret social"
      imageSrc={""}
      description={
        <div>
          <h2 className="mb-4">Destination Unknown</h2>
          <p>
            Travel to another city with all your mates and find out where you’re
            going when we arrive! Jump on one of our party coaches to a
            destination unknown for a crazy night out consisting of pre bars,
            bar crawls, free booze &amp; nightclub after parties all for one
            ticket price. Check out all our current dates below and gather your
            mates for a very different night out!
          </p>
          <p>
            If you’re part of a society or sports team and want to organise a
            private social please visit the Contact section on our website and
            drop us an email, or message us on Instagram{" "}
            <a
              target="_blank"
              href="https://www.instagram.com/fresh2deathuk/?hl=en"
            >
              @fresh2deathuk
            </a>
          </p>
        </div>
      }
      backgroundSrc="/events/the-secret-social.jpg"
      scriptString={`<script src="https://web-cdn.fixr.co/scripts/fixr-shop-widget.v1.min.js?callback=fixrApiCallback&salesChannel=whitelabel-website" data-fixr-shop-id="77358234-320b-48bd-8c0c-bf33aecef89c" data-fixr-theme="light" data-name-filter="social"></script>`}
    />
  );
}
